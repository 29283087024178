import BasePlugin from '../BasePlugin'

export default class FinModCalc extends BasePlugin {
  async execute () {
    // копируем БДР/БИР/предпосылки
    this.send({ recordId: this.context.getModel().id })
      .then((response) => {
        // расчитываем индикаторы
        console.log('Данные скопированы')
        console.log('Финансовые индикаторы посчитаны')
        this.context.getDashboardComponents()['component_fc78226a-41dd-487d-a26d-0a42b78858f4'][0].loadData()
      })
  }
}
